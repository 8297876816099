import React, { Fragment } from 'react';
import {Labeled, ReferenceInput,AutocompleteInput, Filter, TextInput, SelectInput, useTranslate, List, Datagrid, TextField, DateField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {ValidateField, OrderTypeFilterField, OrderStatusField, OrderRunningStatusFilterField, FormattedDateField} from '../../control/field/index'
import {orderRunningStatusValues} from '../../control/field/order-status-field'
import { weekDays } from '../../util/constant';
const BulkActionButtons = props => (
    <Fragment>
        <BatchDeleteButton label="Supprimer" {...props} content="Voulez-vous supprimer ces demandes ?"/>
    </Fragment>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_requested.list')}</span>;
};

const DaysTextField = ({ source, record = {}, label}) => {
    // if(record === 'undefined' || record.every_day === 'undefined'
    // || typeof(record.every_day)=== 'undefined') return <span></span>;
    
    // const everyDay = record.every_day[0];
    // if(everyDay === '1')
    // return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const days = record.repeat_days;
    console.log("Repeat days: "  + days);
    if(days === 'undefined' || typeof(days) === 'undefined')
        return  <span></span>;
    const dayList = days.split(',');
    const length = dayList.length;
    // return <span class="MuiTypography-root MuiTypography-body2">{length}</span>;
    if(length === 1)
        return <span class="MuiTypography-root MuiTypography-body2">{weekDays[dayList[0] - 1]}</span>;

    return dayList.map((day, index) => {
        if(index < length - 1)
            return <span class="MuiTypography-root MuiTypography-body2">{weekDays[day - 1]}, </span>;
        else
            return <span class="MuiTypography-root MuiTypography-body2">{weekDays[day - 1]}</span>;
    })    
}

const RepeatFromDateTextField = ({ source, record = {}, label}) => {
    // if(record === 'undefined' || record.every_day === 'undefined'
    // || typeof(record.every_day)=== 'undefined') return <span></span>;
    
    // const everyDay = record.every_day[0];
    // if(everyDay === '1')
    // return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const fromDate = record.repeat_from_date;
    
    // console.log("Repeat days: "  + days);
    if(fromDate === 'undefined' || typeof(fromDate) === 'undefined'
    )
        return  <span></span>;
    return <Labeled label="Début">
        <span class="MuiTypography-root MuiTypography-body2">
    <FormattedDateField record={record} source="repeat_from_date" format="format_date"/></span>
    </Labeled>;

    
}

const RepeatToDateTextField = ({ source, record = {}, label}) => {
    // if(record === 'undefined' || record.every_day === 'undefined'
    // || typeof(record.every_day)=== 'undefined') return <span></span>;
    
    // const everyDay = record.every_day[0];
    // if(everyDay === '1')
    // return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const toDate = record.repeat_to_date;
    // console.log("Repeat days: "  + days);
    if(
    toDate === 'undefined' || typeof(toDate) === 'undefined')
        return  <span></span>;
    return <Labeled label="Fin">
        <span class="MuiTypography-root MuiTypography-body2">
    <FormattedDateField record={record} source="repeat_to_date" format="format_date"/></span>
    </Labeled>;

    
}


// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        {/* <TextInput label="Nº de commande" source="order_number" alwaysOn resettable/> */}
        <ReferenceInput label="Email passager" source="client_id" reference="customer" perPage={99999} alwaysOn resettable
        emptyText="Tous"
        >
            <AutocompleteInput  optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        {/* <ReferenceInput label="Email chauffeur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <AutocompleteInput optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput> */}
        {/* <SelectInput label="État" source="status" choices={orderRunningStatusValues} 
        optionText={<OrderRunningStatusFilterField />}
        alwaysOn/> */}
        <TextInput label="Adresse de départ" source="from_address" resettable/>
        <TextInput label="Adresse d'arrivée" source="to_address" resettable/>
        
        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);


const ListOrderUnassigned = (props) => (
    <List title={<Title/>} {...props} 
    filters = {<ListFilter/>}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            {/* <TextField source="order_number" label="Nº de commande"/> */}
            {/* <OrderTypeField source="order_type" label= "Type de commande"/> */}
            <TextField source="c_last_name" label="Nom"/>
            <TextField source="c_first_name" label="Prénom"/>
            <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge"/>
            {/* <ValidateField source="repeated" label='Récurrent'/>
            <RepeatFromDateTextField/>
            <RepeatToDateTextField/>
            <DaysTextField label="Jours de récurrence"/> */}

            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            <TextField source="total" label="Prix(€)"/>
            {/* <OrderStatusField source="status" label = "État"/> */}
            
            {/* <TextField source="creator.email" label="Créateur"/> */}
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
        </Datagrid>
    </List>
);

export default ListOrderUnassigned;

