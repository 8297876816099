
import React, {Link} from 'react';
import { Pagination, ReferenceManyField, Datagrid, SimpleShowLayout,TextField, Show, useTranslate , Labeled} from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {ANIMAL_TYPE_CAGE, ANIMAL_TYPE_FREE} from '../../util/constant' 
import {DistanceField, DurationField, OrderStatusField, FormattedDateField, OrderTypeField, OrderActivityTypeField,  AnimalField,
    FullNameClientField, FullNameDriverField} from '../../control/field/index'
    import {PdfViewButton, DownloadButton, RatingChangeButton} from '../../control/button/index'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

const DownloadFileButton  =  ({ source, record = {}, label}) => {
    return <DownloadButton fileUrl={record.file_url} fileName={record.file_name}/>;
    // return <DownloadButton onClick={(e) => alert("Fichier facture n'est pas encore disponible")} fileName={record.file_name}/>;
}

DownloadFileButton.defaultProps = {
    addLabel: true,
};

const ShareClientsField = ({record = {}, basePath}) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    
    return (<div>
     {/* <Labeled label="Passagers de covoiturage"> */}
     <Typography style={{ fontWeight: 600, fontSize:15 }}>Passagers de covoiturage</Typography>
     {/* filter={{going_order_id: record.id}}  */}
    <ReferenceManyField perPage={20} reference="order" basePath={basePath} filter={{going_order_id: record.id}} addLabel={true} label="Passagers de covoiturage">
        <Datagrid>
            <FormattedDateField source="create_time" format="format_date_time" label="Confirmé à"/>
            <FullNameClientField label="Nom du passager"/>
            {/* <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/> */}
            <TextField source="passenger_count" label="Nombre de passagers"/>
            {/* <DistanceField label="Distance"/>
                <DurationField label="Durée"/> */}
            {/* <TextField source="note" label="Commentaire au chauffeur"/> */}
            <TextField source="total" label="Total(€)"/>
            {/* <FormattedDateField source="at_pick_time" format="format_date_time" label="Sur place"/> */}
            
            {/* <FormattedDateField source="at_pick_time" format="format_date_time" label="Sur place"/> */}
            {/* <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge"/> */}
            {/* <FormattedDateField source="end_time" format="format_date_time" label="Arrivée"/> */}
        </Datagrid>
    </ReferenceManyField>
    {/* </Labeled> */}
    </div>
    
    );
};

ShareClientsField.defaultProps = {
    addLabel: false,
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_running.show')}: #{record ? `${record.order_number}` : ''}</span>;
    
};



// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderFinished = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
        <div/>
        <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
                <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock} />
                {/* <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/> */}
                <div/>
                {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="show">
                    <TextField source="name" />
                </ReferenceField> */}
                {/* <OrderActivityTypeField source="order_type" label= "Type d'activité" className={classes.inlineBlock}/> */}
                <div/>
                <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
                
                <FullNameClientField label="Client" className={classes.inlineBlock}/>
                <FullNameDriverField label="Conducteur" className={classes.inlineBlock}/>
                <TextField source="client_names" label="Passagers"/>
                
                <TextField source="note" label="Commentaire aux passagers"/>
                {/* <AnimalField/> */}

                {/* <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
                <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
                <div/>
                <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
                <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/> */}

                <div/>
                {/* <TextField source="from_address" label = "Adresse de départ"/>
                <TextField source="to_address" label = "Adresse d'arrivée"/>
                <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge"/> */}
                <TextField source="passenger_count" label="Nombre de passagers"/>
                
                <Divider/>
                
                <br/>
                <ShareClientsField/>
                <br/>
                {/* <TextField source="original_total" label="Montant sans frais d'attente(€)"/> */}
                {/* <TextField source="wait_amount" label="Frais d'attente(€)" className={classes.inlineBlock}/> */}
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Montants</Typography>
                <TextField source="commission_amount" label="Commission(€)" className={classes.inlineBlock}/>
                <TextField source="driver_amount" label="Commission conducteur(€)" className={classes.inlineBlock}/>
                
                <TextField source="total" label="Total(€)" className={classes.inlineBlock}/>
                <div/>
                {/* <TextField source="tips_amount" label="Pourboire au chauffeur(€)"/> */}
                {/* <TextField source="pt_name" label="Mode de paiement"/> */}

                <br/>
                <Divider/>
                <br/>
                {/* <Typography variant="body2" color="primary">Déroulement</Typography> */}
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Déroulement</Typography>
                {/* <div/>
                <TextField source="wait_min" label="Temps d'attente(mn)"/> */}
                {/* <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/> */}
                <div/>
                <FormattedDateField source="to_pick_time" format="format_date_time" label="Commencé à" className={classes.inlineBlock}/>
                <FormattedDateField source="start_time" format="format_date_time" label="Passagers à bord à" className={classes.inlineBlock}/>
                <FormattedDateField source="end_time" format="format_date_time" label="Terminé à" className={classes.inlineBlock}/>
                <div/>
                <Divider/>
                <br/>
                {/* <Typography variant="body2" color="primary">Déroulement</Typography> */}
                <Typography style={{ fontWeight: 600, fontSize:15 }}>Factures</Typography>
                <Typography >Aucune facture</Typography>
                {/* <ReferenceManyField reference="invoice" target="order_id" addLabel={false}>
                    
                    <Datagrid pagination={<Pagination />} >
                        
                        <TextField label="Nom" source="invoice_name"/>
                        <TextField label="Numéro de facture" source="invoice_number"/>
                        <TextField label="Date de facture" source="invoice_date"/>
                        
                        <DownloadFileButton/>
                    </Datagrid>
                </ReferenceManyField> */}
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/>
                <FormattedDateField source="cancel_time" format="format_date_time" label="Heure d'annulation"/> */}
            {/* <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            
            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <div/>
            <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandé"/>
            <div/>
            <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/>
          
            
            <TextField source="total" label="Prix(€)"/>
            <TextField source="commission_amount" label="Commission(€)"/>
            <TextField source="driver_amount" label="Commission chauffeur(€)"/>
            
            
            
            <div/>
            <Divider/>
            <br/>
            <Typography variant="body2" color="primary">Déroulement</Typography>
            <div/>
            
            <div/>
            <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/>
            <FormattedDateField source="start_time" format="format_date_time" label="Démarrage" className={classes.inlineBlock}/>
            
            <div/> */}
            {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée"/> */}

            {/* <TextField source="pt_name" label="Mode de paiement"/> */}
            {/* <TextField source="driver.email" label="Chauffeur"/> */}

              {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
            {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/> */}
        </SimpleShowLayout>
    </Show>
)};


export default ShowOrderFinished;