import React, {Fragment} from 'react';
import { Pagination, ReferenceField, downloadCSV, List, Datagrid, TextField, DateField, EditButton, Filter, TextInput, SelectInput, useTranslate } from 'react-admin';
import jsonExport from 'jsonexport/dist'

// import BatchDeleteButton from '../../control/button/batch-delete-button';


import {GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER, USER_TYPE_DRIVER_COMPANY, ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_ATR, USER_TYPE_PASSENGER, USER_TYPE_CONDUCTOR, USER_TYPE_PASSENGER_CONDUCTOR} from '../../util/constant';

import {FormattedDateField, DriverStatusField, VerifyField, AvatarEmailField, FullNameField, GenderField, 
    DriverCompanyTypeField, DriverCompanyTypeFilterField, ActivityTypeFilterField, ActivityTypeField,
    GenderFilterField, LockField, ValidateField, AvatarField} from '../../control/field/index'
import {NoticeSendButton} from '../../control/button/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        <NoticeSendButton {...props} toClient={false}/>
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.customer.list')}</span>;
};

var userTypes = [];
userTypes.push({id: USER_TYPE_PASSENGER, name: "Passager"});
userTypes.push({id: USER_TYPE_CONDUCTOR, name: "Conducteur"});
userTypes.push({id: USER_TYPE_PASSENGER_CONDUCTOR, name: "Passager/Conducteur"});


const UserTypeField = ({ record = {}, label}) => {
    return <div>
        <span class>{record.user_type === USER_TYPE_PASSENGER.toString() ? 'Passager' : record.user_type === USER_TYPE_CONDUCTOR.toString() ? 'Conducteur' : 'Passager/Conducteur'}</span>
    </div>;
}

const UserTypeFilterField = ({ record }) => {
    return <div>
        <span class>{record.id.toString() === USER_TYPE_PASSENGER.toString() ? 'Passager' : record.id.toString() === USER_TYPE_CONDUCTOR.toString() ? 'Conducteur' : 'Passager/Conducteur'}</span>
    </div>;
};

// const FormattedDateField = ({ record = {}, source, label, locales, className}) => {
//     // const classes = useStyles();
//     if(typeof(record) === 'undefined' || 
//         typeof(record[source]) === 'undefined' 
//         || record[source] === "")
//         return <span></span>
    
//     moment.locale('fr');
//     const value = moment(record[source], "YYYY-MM-DD HH:mm:ss");
//     return <spam>{value.format('DD/MM/YYYY')}</spam>
// }

// FormattedDateField.defaultProps = {
//     addLabel: true,
// };

var genders = [];
genders.push({id: GENDER_MALE, name: 'Monsieur'});
genders.push({id: GENDER_FEMALE, name: "Madame"});

var lockeds = [];
lockeds.push({id: 0, name: 'Non-vérrouillé'});
lockeds.push({id: 1, name: "Vérrouillé"});

var validateds = [];
validateds.push({id: 0, name: 'Non-validé'});
validateds.push({id: 1, name: "Validé"});

var activateds = [];
activateds.push({id: 0, name: 'Non-activé'});
activateds.push({id: 1, name: "Activé"});

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable/>
        <TextInput label="Mobile" source="mobile" alwaysOn resettable/>
        <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/>
        <SelectInput label="Type" source="user_type" alwaysOn choices={userTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />

{/* <SelectInput label="Activité" source="activity_type" alwaysOn choices={activityTypes} 
            optionText={<ActivityTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            /> */}
        
        {/* <TextInput label="Ville" source="city" resettable/> */}
        {/* <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <TextInput label="Identifiant unique" source="user_code" resettable/> */}
        {/* <SelectInput label="Identifiant unique" source="user_code" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        <SelectInput label="Activé" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        {/* <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
        <SelectInput label="Complété" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
    </Filter>
);

const exporter = orders => {
    const dataForExport = orders.map(order => {
        const { user_type, photo_url, gender, postal_code, create_time, activated, validated, locked, full_name, online_status, plate_number, brand_name, model_name, id, client_id, driver_id, status, c_email, c_first_name, c_last_name, c_mobile, d_email, d_first_name, d_last_name, d_mobile, ...dataForExport } = order; // omit backlinks and author
        // postForExport.author_name = post.author.name; // add a field
        return dataForExport;
    });
    jsonExport(dataForExport, {
        headers: ['last_name', 'first_name', 'mobile', 'email'], // order fields in the export
        rename: ['NOM', 'PRENOM', 'MOBILE', 'EMAIL'],
        verticalOutput: false,
        booleanTrueString: 'Oui',
        booleanFalseString: 'Non',
        // rowDelimiter: "\t",
        // forceDelimiter: true,
    }, (err, csv) => {
        downloadCSV(csv, 'comptes'); // download as 'posts.csv` file
    });
};

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;


const ListDriver = ({permissions, ...props}) => {
    const translate = useTranslate();
    return(
    <List pagination={<ListPagination/>} title={<Title/>} {...props} perPage={50}
        filters = {<ListFilter permissions={permissions} />}
        bulkActionButtons={<BulkActionButtons />} exporter={exporter}>
        <Datagrid rowClick="show">
            <AvatarField source="photo_url" label="" />
            {/* <TextField source="user_code" label="Identifiant unique"/> */}
            {/* <GenderField source="gender" label={translate('civility')}/> */}
            <TextField source="last_name" label="Nom"/>
            <TextField source="first_name" label="Prénom"/>
            {/* <TextField source="balance" label="Wallet(€)"/> */}
            <UserTypeField source="user_type" label="Type d'utilisateur"/>
            {/* <ActivityTypeField source="activity_type" label="Type d'activité"/> */}
            {/* <TextField source="company_name" label="Nom de société"/> */}
            {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField> */}
                {/* <TextField source="company_name" label="Nom de société"/>
                <TextField source="company_address" label="Adresse du siège social"/>
            <TextField source="postal_code" label="Code postal"/>
            <TextField source="city" label="Ville"/> */}
            <TextField source="mobile" label="Mobile"/>
            {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
            <TextField source="email" label="Email"/>
            <DriverStatusField source="online_status" label="Statut"/>
            <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
            <ValidateField source="activated" label='Activé'/>
            <ValidateField source="validated" label='Validé'/>
            {/* <ValidateField source="locked" label='Verrouillé'/> */}
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <TextField source="creator.email" label="Créateur"/> */}
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
            {/* <EditButton/> */}
        </Datagrid>
    </List>);
};

export default ListDriver;

