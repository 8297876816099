import React, { Fragment } from 'react';
import { Filter, TextInput, ReferenceField, SelectInput, List, Datagrid, TextField, DateField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {OrderTypeField, OrderStatusField, FormattedDateField, FullNameDriverField, FullNameClientField, OrderActivityTypeField} from '../../control/field/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

// const FullNameCientField = ({ record = {}, }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         {record.c_first_name}&nbsp;{record.c_last_name}
//     </div>
// );

// const FullNameDriverField = ({ record = {}, }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         {record.d_first_name}&nbsp;{record.d_last_name}
//     </div>
// );


const Title = ({ record }) => {
    return <span>Commandes terminées</span>;
};

// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Nº de commande" source="order_number" alwaysOn resettable/>
        <TextInput label="Nom, prénom du passager" source="q_client" alwaysOn resettable/>
        <TextInput label="Nom, prénom du conducteur" source="q_driver" alwaysOn resettable/>
        <TextInput label="Adresse de départ" source="from_address"  resettable/>
        <TextInput label="Adresse d'arrivée" source="to_address"  resettable/>
        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);


const ListOrderFinished = (props) => (
    <List title={<Title/>} {...props} 
    filters = {<ListFilter/>} sort={{ field: 'create_time', order: 'DESC' }}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show" >
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            <TextField source="order_number" label="Nº de commande"/>
            {/* <OrderTypeField source="order_type" label= "Type de commande"/>
            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="show">
                    <TextField source="name" />
                </ReferenceField> */}
            {/* <OrderActivityTypeField source="order_type" label= "Type d'activité"/> */}
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            {/* <FullNameClientField label="Client"/> */}
            <FullNameDriverField label="Conducteur"/>
            <TextField source="client_names" label="Passagers"/>
            <TextField source="total" label="Prix(€)"/>
            <OrderStatusField source="status" label = "État"/>
        </Datagrid>
    </List>
);

export default ListOrderFinished;

