
import React, {Link} from 'react';
import { ListButton, EditButton, ReferenceField, SimpleShowLayout,TextField, Show, useTranslate, ImageField, TopToolbar } from 'react-admin';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {Status} from '../../util/constant'
import {ValidateField} from '../../control/field/index'
import {DataLockButton, DataUnlockButton} from '../../control/button/index'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    // <CardActions style={cardActionStyle}>
    //     <EditButton basePath={basePath} record={data} />
    // </CardActions>
    <TopToolbar>
        <ListButton basePath={basePath}/>
        {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.UNLOCKED &&
            <DataLockButton basePath={basePath} record={data} resource={resource}/>
        }
        {typeof data != 'undefined' &&  typeof data.is_locked != 'undefined' && data.is_locked === Status.LOCKED &&
            <DataUnlockButton basePath={basePath} record={data} resource={resource}/>
        }
        <EditButton basePath={basePath} record={data} />    
    </TopToolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle_model.show')}: {record ? `${record.name}` : ''}</span>;
    
};

// const TypeTextField = ({ source, record = {}, label}) => {
//     const locationType = locationDict[record.type];
//     return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
// }
    
// TypeTextField.defaultProps = {
//     addLabel: true,
// };

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowVehicleModel = ({staticContext, ...props}) => (
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
            <TextField source="name" label = "Nom"/>
            <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand">
                <TextField source="name" />
            </ReferenceField>
            {/* <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type">
                <TextField source="name" />
            </ReferenceField> */}
            
            <TextField source="seats" label="Sièges"/>
            {/* <ImageField source="luggages" label="Icone pour la sélection" size='48'/> */}
            <ValidateField source="is_locked" label="Verouillé"/>
        </SimpleShowLayout>
    </Show>
);


export default ShowVehicleModel;