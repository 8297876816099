import React from 'react';

const Logo = props => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
  x="0px" y="0px" width="203px" height="50px" 
  viewBox="0 0 203 50" enable-background="new 0 0 203 50" 
  >  <image id="image0" width="203" height="50" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMsAAAAyCAYAAADyZi/iAAABHWlDQ1BpY2MAACiRY2BgMnB0cXJl
EmBgyM0rKQpyd1KIiIxSYD/PwMbAzAAGicnFBY4BAT4gdl5+XioDKmBkYPh2DUQyMFzWBZnFQBrg
Si4oKgHSf4DYKCW1OBlopAGQnV1eUgAUZ5wDZIskZYPZG0DsopAgZyD7CJDNlw5hXwGxkyDsJyB2
EdATQPYXkPp0MJuJA2wOhC0DYpekVoDsZXDOL6gsykzPKFEwtLS0VHBMyU9KVQiuLC5JzS1W8MxL
zi8qyC9KLElNAaqFuA8MBCEKQSGmAdRooUmivwkCUDxAWJ8DweHLKHYGIYYAyaVFZVAmI5MxYT7C
jDkSDAz+SxkYWP4gxEx6GRgW6DAw8E9FiKkZMjAI6DMw7JsDAMKzT/5YHklZAAAAIGNIUk0AAHom
AACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZ
cwAACxMAAAsTAQCanBgAAAFjelRYdFJhdyBwcm9maWxlIHR5cGUgaWNjAAA4jZ1UWW6EMAz9zyl6
BO8mx2ECkXr/C9SBhIGK6Wj6UEB5dryH9F1K+mpQgwQNxCAoIiQKhBtli60uTkriQgQ6adaZALzU
Jo7FcEIyNHb2MKSgIGUIbLUaS+FvbF5reE1j07AwLUdkHyJ9qD9H/ODksm9rz4ApmZg6254vjrTt
4R4Vgn4Acz+AYhSyg3/A4FOUU6Kg3VDpnmA1P/PLk7/orwffDG392AVVDs9+4gmmZ0RnHo9Ik7fU
ppEzgLDVCD07WTa3kBAwcm4PRW+jFDXS0xgIDi1u521xTiHM0eZ2nBza23ATdtOXGh5AvJiMWUtt
2GjqPifK79r2Cp+2//+GjqtxQmVtQzwGGdHi0t0p3iEGUaOK9ovGuKdb/dI7hXfyEcgltVxwmxep
837XpJa7iFmmpX1noH2Oatbb1MgYu9v2y4DHmkv6AXsr5+I4+bWfAAAFDXpUWHRSYXcgcHJvZmls
ZSB0eXBlIHhtcAAAWIXFWF3SozgMfNcp5ggg2TIcJwnwNlX7uMefbjkhBJOfL7U1m8wQPrCldkst
C+Tf3//IL3w0lyR2saUMpfPezc+OS9q5evbio882qc7L+XxeVHF99MQruVhOk3VpKl0yjB18lDSU
U8HEbOWU5pwcvzBohkmqttisnV3KYKcyOCb6RGfea8e//eJzMd4TegCa5Atx2KneWIcHkrsZXDtz
RlpnaJeHNOVOlOCWEpfMdbZOJ+Dp8IUJKzbiWm9uCV/H3QuuqsV/XfCbcexNRacYdsKlhGNvRbvd
V6/LU6DAyKwpJd8tTSVucnlDSfh2dsJylhIfnQtG6RyIS3ge+Q0kiqPiOFUHQFSsID5kpAxYFjzw
/iMKQECoEAj1MZgawRBG3O57LyBsKSCWqCqx21iQ4BZvOJvvIcK5CU4nLGUAHpLXB93L3iSm2AEB
HSCoX7h4qavnQBwzJmaGnkuF12q4bw1XrGlCWl7Ny2vAx3hhcNobF1i3klIuXnl6z8axcaFsCiKB
QUvWiM0SK4eGaL4uk3HcLQ7uOf6WPPKd/9a93P1X+we+O6h7Qe5gepwdJoQ8xdBMb5ODZhKTNCMh
Y6hmGlr80ii7NUe50iCMKXQfdWwkousgHY/84G5Oqf7SyVX3UBh++3rMiUcJxfeh/lHPxsLB8+7B
7LU6eTXZH5mW1vZqOqM+PTdNNYzuZaxm5R3kV2ZDgSd3siwIc3/N6ZHnKPo5YjZDgyuGUNUpsdxY
LHKOhAUazuQYQRxmmOD+cLKBESHJ3gNBQnVSg0OwN6F+ZsuQ9mQDLin+TcEa4oLRoxA4/gBIlPo9
AqaBlbUe3BA8bBYxBtsRkRzkLjlRlFU6QNH3ylvm39wcnJuBB2IDEJwJlgNKccv4gWfFleLaoGPd
Huu2CUSXPVrZEhZ8pZWvLhjoyQf5AoYCfjLP4BZYyBJR80xwQnLjQ5gYCrasIjpYMndByBn4rrlU
08fk6YSRO2LmPrHAx8gi3ChRUQBCc5QId6pQ2m2X3/CC5iDyBv7LuNsmwWfp79ckdqWLj8B1L7kn
avq2e0XANyJmc7EbHep/kqm7RH1LvGyZf0n8BkEpYDJXrJ+o/6iwbM0xfjiD2Z6izSmEyqT6vj6y
jNzr4/IYl/chwMw+NizQLy3/H9GP/QgByMitESwys2kPzEYknLq2kCIHeERnYD/mZA3mg7HBmgQo
RVr+X9CvSKcn9EvL/3f0S8v/w/a05Y9TyR2d1MZsuYdIvovRlxJxMhWdQXQfBfyxCKKu13Cwht8k
MkR/TcIt5AHXDC/Htwh+KpGjDfLvSuQHMWpDJM9LGc8YJVTHw3LGR7NCLUSLITGw9tAe9XlSDmAd
hzfWo7XPX2AWu8xjY3ar97JdP9mIx7u2B3hbGaRZEp86+MRxmHqPy9msJh4hHpnovluiHDSdXy3x
adReK6sVlhxuPl90SVLbpKZLeqOsV13tj5TVCku+U1YrLPlEWboctQ7xqLM2HHLYcTz2kZsETWuO
bR6z/scKuSI46Hblv+gf2cXIqzZmRfCmo/0riG7iPGpT93giVKyQt+Db7n0I605p1W6b8vHJIwR6
E3Qk6jQR4kQK8EOFARAXG3Eu8e5r4ksWBhuBjuBrKC/hd5PPFcdjjm/xsl7Jpxy0FGyMIvulfSvU
6CveYN31tX9td+1GDl4eZlbkSAut7/3kD9wYpjbAXWP3AAAjVUlEQVR42u2deZxlVXXvv+vcW0NX
dfU8VVV3091MzdRICzIIKIOIohiRkJiIkvg0BhOSiEkk0ehzig9i9BM1SHygZDAGFRVEQGSQeRBB
6G5oGmigoZumB3qq4dY9Z/3eH+ece8+995xb1Woe+SS16rM/995z9l5777X3WnuttdfeZZKYhEmY
hPGhDHDe395Ze1AqCQugFFSRSrjKBEEVM3B1AAI5JiErgQkjwMwAEAFmgIHBbKHDzOwAYBEwC+gC
QmCXYCPiKYNVQuvBwEEorgdAERIgIYEEQRBRLkdI1tKhr/7ZKa80TSfhvymU/5PwTgdOBk7EbKVg
P2Betr5kmm/HeFbiEeAu4BbgqVeaKJMwCXnw62aWU830XuBMoEdQWyBo+p4sCrMQs4AjMN6DBMYd
iCuBbwDRK02gSZiEFH5dzPImwceAY0ltoBxTqFwyhkZDXtoxigGlktFVLtHTXaa7sxQvN+IE4ATg
U8DFwBdfaSJNwiRAW2YRwDTgVWC3S7wVuBhsB/DXmG4BVoJ9XHBmbFC0r2z7UIX9Bqbxnjfsx+6R
Klt2jPLC1iE2bBli49YhXGL2tG56usq41A98AYLzwf8I+PFEO/WeT93crk8JWM4zakte9k1sjuV1
rp7XLMkhkXJ9Q04rEYajRGNjmJdyMFliq40HBoE34JfHv8zACOJ3tSZk+ln7asgcSiF4gIUdYGrT
FovfBwK3tuNsZgRBwNCuXVRHK1hgTAQEyJM5FFAvV2t2K01zm5FTXdo1uYMCKJUx848DU4G5wKgU
fFKw0XAMAQ7ANZe9u4anHI+vNTVB81yl3SJYB5rnHlwP7A++X9wau9mMpZJORnYmWYO8DewaGmP2
tC5OPqK/5d2q9S9z+yMvct9jL/H0xp3MnT6Fvp4OItf+YDcCfwf68wlRfhImoRnMEkHmYLwDuBDo
A0D2PSWuJavN5VbmLAOUSlHMkMYssOsRR7lKjwDzDAfsTaSshj8Otr+rdInQB4BLrA2jpNUCzJ/R
zWPPvsyFl95Hd2eJmVO7WDy/lxXLZnHo0pkcunQm57/tIK667Wmuvfs51m/aycK5UykFhsSHgcOB
0+ttmYRJ2DswaT74JxE7QRsQF0NwpVmEjSPwywAdHSFhGEDMdq+JVxo/HOq8JZQujAFoO/AaiSpo
FLPuYjVFKPksB8ZYNWLD5t1ELipVp1KN6CgZC2b1sPKAOZz9uqWc8/pl/ObrlvKlq1dzzV3PMmta
FzOmdhI5b3DxSMk40ozRvdgiOhU0kwYmaykcANuIPXLNeY4BFtPqcAhAdwKbEpGwAlgOyuazuJyu
R4wCJaCbVoYPgOFMpUGSr7mxAVAhdr+3g32BpcBMoApsBJ4AdoxTrjtpY7Z9QYJjLPNsCq3iNy/f
ycBs2gu4Ou0tQVnHmkf7VP7eBOxswNQ6J2YCpyBhQUnAIuRfBhtCGhWsNQvPMNSd0eFKwC+ADcmY
ADU1jAGJ90gMmGmDxKLGNcGhrsdWgVGDxbG2bA+7OCZVLg22S/wt8Chovhl/LDgSKcFi9HZ31HqV
7s8Mj4Z87471XH/fc5x8xCB/cvZhXPCOQ1m5/xwu+dYv2LRtmAWzenDXIWFo9weBrbCAAu2vYVwM
7KaJqIlAhKV2nDKSxi4BO75xNCyly+dAFyWM+2HQuY1jmuQ3X0jEC0TBaQS6qrnlQgHwkLATkleH
GNzd2kMFuF0EwZfqfa21qRf4CPBbiP1bZo+oAHcILhN8x0Rii3ja1g7iPa/5TQUD4Ie4/TYIAvUi
eyyZiA3MIvimTO8HcHcCKxUZkC1gWBATvcEuukRwfMH4HQk8OA7aQwXfbiRhI13UYNvV4GIzuwx4
On1QBgir/LmkP00KjmbXk6Ya0i+V5Fcf6HbQMcmbfwO9Kx4oOwR4QnAUpg8B54CeBfYAncB84ABJ
+wBM6QxYMn8q1dC59u5nuGf1i/zp2Ss4fsUClvb3ceFX7ub5l3azcO5UqhGHjY3aDwP0ljyjuOnJ
vAkyCsTm/Sxge9PzzWrAnGECcThY4gRgW9MwpF9CZJHjuHyKyaYW1H88sRTeBupQbIA2gQE+rSZs
6oN8pKTrhea06V8XcGqSrobgHSAcJXwvgEHqK1oW5tb6JALQQnLNaWbLhbtANhsREUvq8cCBeUib
m9BuLi5iY63PWsZ6dAJ158EOoYbVO9a9It0n14vJDnl3ulMuKUlkP7dKGpVArgPlfk9sN+l8In+X
O18X9oTge4J7JK0h9H/E/RhMv4XpvUjngk5DWiLXckl/JtcjUeQEBvv29+GRc9Fl93Ll9WsZnNvL
pReeSN+UDjZtHaIcQCQ7o1rlL6OKEY3VU0wvy6aB2Gazuh+iOJVd9LvAPfEyeRw8UPP2JdEEdZ+G
jkqnLTGZ8vB6RtZUkwCHlgRggR9lsciP8vMJ0GisMSceKqPf4AGDOUW4m3GYOIswuBovYaY4xe93
FpTbkxWYBjty+2AMGULuCA0IlcSE/gKhBTG5Gv5UXMZzUh75f6m/lkiwIGHGMaQ1yMN4IqSzJJ0Y
AtfHcf8grquR9kliUC4Avk/kr5XrUsGjwHn18g7oIBmXycFDoVB4JOQJGVxrTXzR4HBc70TaEEXO
jN5O9pnfyz9+bxWX/WA1M/u6+MIfv5YwcnbsrhAgrMTnLGCZWeI2rQmkBlINFkicfFklDZiUcVeO
6/qcY+KARnUoDxqX/bxkArmtjMkmK8qXtBRTAG7IubIo7zjp7YjTkeXX09y+DEXa9iERssDABARU
1pk6kBkJaurYXuHIebi3OGq4Gsc+SAZvP0kVuVbJPZ7IcbpSrg8r8nfJFUpaIfERufqSfG/Hdaik
UaFQ0qGZssj1XUn/W+JngpPkWiIXoJNwFsrZH+kkd0fxBP0WYplc/xJFTjkwDlg4jSuuXcN3bn2K
wbm9fOw9r2bztj14FLfTjauy8qCVV1i4lxQaLJ7s+SNs5kcir9dfoMaO521Jxvqo5Oe40QsxjVkC
vGG8vMU4+Ig8nZ6NkyPb2ontltRwgguTFk2U7oon9WDj6j0+d7VWXsD1RalYksxC6siiTgx8fwz4
E7B1oCvBfg5sBx8gDoA8EHhfo9lS+/Zo4yMBPAJ2VmD2lLsA3wQsAL4tOAdxi+AHQC9waixFRRAE
AKHJ3i18naRPBmYsHZjG3//7Qxy4eAavXdHPGcct4YZ7n2VJ/zTc/dXCTkMq2rRcGG80ioyt8TKx
8XxGTv6BRtuErDjPGx2EHQ18s3H8GuoDggRNm4GO4TVJ0WHGASEwnW7tp/LDxAJgbsH7YyV6zBhu
tMua66qtKeO1P87vQrKF1uoD25XQ/o008aDQYH3jtD4Gxb3Ls1dbn7XBsBXj9tZ2EBjci3xX9nk5
6diPMdaBjgC+CToWOAg4eiINrDdKOLYaSkeUTO4eITEY798AMB/Rlaj3hwLDYMOpxHV3SkEJR4Sy
TyF6SuYf6SoHzJzayae/fj//8ek3ccFvHs5dD7/A8EiV7s4SQp9HdlgBwQZy2r7RYi/Lm0hV0Xqe
/jSbWU7JfDgyyVBtIfvew4CgZDFDtwULAhD7t6nnUuB84s239cTOg2botID9gV+0FwrZPqWCoDiP
x5bGQA7GF4HPAacAGcltgBY0R5LXYwlaNwnz2lDE6gXwILJ3pOOcx2ZZSCaKKkhXIZ+G/DLk5yE/
GncmlJR+15gUnBjJPHJHsfK9GPcSrsdxLQbNxX0E931xX45HW1K1zaOIKIqIBHHkg13k4haPIubO
6OLZjTv5l+sfo6e7zNtfvy8bt+yOu+h+KESvKtBcBvIeKjYycvYqrIm5JjThD09Iu7toEBvwNGkB
zd8NXoXYUZgnrSO2bfqatYl6GfuHWO+33chuzqsr2dSejhdrLrW8Tlyn59s0qZ6f2qTAQI6GI4lI
otr4XLFgLdSSlKMpqSU1OXcosseSNCVtUH3c8twuGWZJKn4w0YGzXqCJJY87ElE6Xtj2gLqDQNIy
iZKkn0iajzRPYkdStkNiuN5RiKIIKaREhOEIO1OiGoVi4bypfPsn66iGzu++cTkzp3ZRqURpO/4w
bzIiDTY2FpBGkTYihTkdWljHUx/dcYjQC+oR2j4+8Vq17kbLBsCOTeRFQ55Gtq1ZaS3rQV3uK8jM
/KEsnibrRFnsllOnmp6Tg0dZdPFeV579NwpsIn9TdbDGjb80TNC2aSoSj3FUS1Iq7OuQMIuQuA9p
Z6M3LDvonpOS565RYW92Kz0ggniYE6Md17wEx9FIU+Sal5RdnZQvZyeT4ZCsNOYRuA8JfVJyerrK
vLRtiGvveIquzhJHHTSfrS8Pk3jrfqMuIWqEKrcOmABGkgGr5FCuNXBtYnAMtZVl7wdUjd+PAmbn
D7xo4ZoCfEIdGczdOe+T7ynDtb5rLOMJg1huu9NlMZ5oMqTBHOk/CtoUbz+0vOunru3E+FWc8vki
p92F5TPsrrAhuYe4N2oqMbN4gFRCXvpGzaVbS552vikR+9LFjwhsGcb1RoARgUYTtzBI2ldy5Doq
wbNUUr/kD0s+KmlxDWfkKBREMUertjdiF0tUoihixtRObn1gAwAnrhxktFJN6vJ5cg5pmowDoJk5
BBwGhuPPFgZbAJqTL50KJm+sDx0J6mpdJ/LWj3GZ6TDQMopErDW3pwjSXZWYiHvRgLw2ZZirfV9c
Avd5wJxc2kvDoJGcd/Mkzc2qXMU0L9wdaWWKYhzDseqeLnLFKhikIfqlWMAq0N9QDX4fWV/rILQs
wsLsfUZweUy8EnHFO1EpgrEp6cD2I1tjprPl3I2pC7EBeAAjQDquhs7TekS88ZtwvjGG62qwd07r
7eTpF3YwUgk5dsUAs6d3U61GlEsG7icAqzMdHSiYTMONnw15OmKGYWuBklEERwCP5eeN+5EuxBaM
h4rlxHFm2+MJ14RtL4/EjcslBacVWrNZJt69IJMAFy4GAsv1lqe0H8opXQJbAGyemHDZGwGUW3ox
+Fn1XTql1DCwn9AUyZHEQY0l46ldBOW3EJV/2tqY7HcD06Wgy+NfZWRR0n8HS9BGQMA7DSJKYYQH
M3GbhekrmKrmpZLiyZnUnzkf4o7SmKV4ubwGgneWAti+Y4SHHt/McYcP0j+nlw2bdjFtaidBYIc2
0aO/gJ7D8e5ZgXvWbAFxjFQ8QSbkJWJF27dmNctgAiJ+CrCS2M3aGr6SCsZxmW5ikyl1rY5/nmYC
+NIdS9dAQUeH4nqsgPbeD/zil18I89pYiOtgKfhuXj4zTgJuy2aOyV3tgLADql3gwe3C78tVuzKq
Gc71uPAwwqshPjaKKoYqnWi0hMSDMv+xpHe6+xp39Ur+Qbm2yfWozOe6/AOS7pd0mtDzjr/dSf6U
qIeRJWqi/TyKnCldZXYPVbj74RfiHrh4ceseero6iCItirtcO5eQ6wnDarbFLvKhv066CUuvJVDb
UMwdxAnFA9ThKBpcqxMd/7yax3nf4BGaALK2WWvesIECW2FPoi7tLrBD+mmr/v//SS4f9SYDvwxQ
t2NqKtb9oKNbqZT9pa0p90thsjxnRJ18IbASMYRxAKGdgezLmN+N23GMBX+H+TawwzCmIw2CzWqs
pO49Ap6rVn2oEljv5y54HT1TyriL3zhpf84+9UC+85O1jIxW56TFkhYP5g2sJUwiNYV3197HITIN
NuP40EMcFl84yVQL35gQwpXFNWc3BsbH9SsaK02Y8v1ptRySFEczDBQg2ZVoZ7m0lxj81Vr8a+pt
DlmTHfyWNy+nkrBwKIyoQSFVizNyA2gecDhiM1hfchjt48AniDf/tgHDiEpSU0OEaOZKJYDRzo5g
6+Prt/VOn9rFCSsXIsGbjl/GY+u38dDjL7JkYHpHUncKiwoGZFcy8LsL3i+sT4y2VBgilhBTxie+
xZ4+IqRc/SlKcKWELIjUNcyipJ3jTAyrf7Tjq9qmnNr01RpX2qKVSHHIcUx75UqqXYnxvsvy6xms
HWqceMBAS58bG7UX5dvgiY0Lb1k1otppjWJkXdlFyloJk75eCjwPSsOpB0FTBFuSW8KsaDRlTbVL
zJnexeVXP8wJKxfWBvmyqx5iSmcpr62DBTrsbsyhtrI0TxIbqONKA4ZzJ9ITxEcOThif+uOOuhMf
ODqCCYnHCVg/6Qrblt8ZH0+2xpoNrKI87i5wLVTQSjmzRFBJO2vPGnu0MLVTG3YC8lo9UcYYxyeR
TxG1CMBYDfMW90qk8SuY1UAJSyhZK2d98YoBQA9GKTHiX0YaAzpi4x5h7ExwtDv30IWYM723k1VP
bOaOn2/ghJWLeOyprdz/yPMMzJ2Kh1G1iUq5QZHxTrshtLOglwsaXb6Fk2kHcezVBJglxtXGNuiI
1V8bJD7rU4iD1Hczzt5dS5hVIcrEVd+OaRpweGFeibFk935Btg2ZoruTVWpXQfnB5tmd55O0ljbl
NLOJDtZUXrDezC4lNXOzRWRrm3ElNksL1beNy4bGsrauVWk2xiqggjiO1KAxDgNmI8VuObNuYN9Y
p1epjt6aKb0Y0WsGHYFxzc1rOWHlIq697Qkqo1WQcGlrpi3dqCjURS/H4tZeLhBF82UZ0hbrMJ3A
fUwELHH5OvEZlPxMD4OW05ZZqEVMZIVTbr7kgEndo1gkorPqdJsOWOwxM9rUK40lUeQLUKt/zeFl
5Fh8S1AezFd9/6CwSbnVFyx4xeX1uMQlQVBGlo1DqxGvAVqZJa7wGaTGVaO5JrOjLUgGwSzesW+g
GXOJOXcV0nEW2B0SDyB9WkBQCv4SC9YpCi+QuNzMsMB+WiOqHCJl6351WsHcmVNY+/QWbrv/GVY9
sZn5s3uIwgiM55O6IY4Jyz2RaPBsomRuKBjzAaTpwM6a9z0fZptxb+IomM44kNr2baJonwIeAF7f
FlF6dCYYRxVLmFzt14ym/MUv06FuX6dGkpVqbsGKtj5xoz9bgGGexe7yLZq4257aKe9279X8yHpF
ROQhpVIZsvVZAbMoalHDHplAE98Yn+Wrt8Qy8dhmdhZijcRapC9Ltg6z1yiKXh+UStNkXKOwilzn
IgUEwU1BuWNTOiIKQ9y9foey9JYUdymAauh87rI7KJcDujoCPDYqV2WGcn6b0b8S2AmaXzDs3Rj9
JKcG2+CZATxrsSr2uvZjmU7YtqrYDuBn4xE+na8qXvFaoF2uenPG32eRUrdOnvFuuGtYrj5EkJxR
apS3xuWI7SRqWtaQTxbzsrD5wJZkl7qWJ7uQWlJftoOeRNMFeXeVeWaxSOwuWbyPJgmPQoKgbgWo
SfhDbWVpYZaNgifB9mtYohthpjlvwfhhjQ6pL0dgZt9zZ56kz2JslutxMzvMYEWk6B8Uhijyj1oQ
nAS2EQ9/HoXhppQcKTGS2jsk3pFS3D2pxiMQhKGnRLgjQ41BiuFQxodB4PH2WawrWbp/xjjMkrYq
vfqj3kxqM8mgC+xOtV9+agUmkM8TMqI2HJqxbazd4Y/6opJ4h5tyJpN5zN0Xp3YVzU1UZvM21bRb
DHkNAqtSLsnmacRV75J74/egweFotfY1jkby1uIyURg2SqImkiXMkkvHW0D7pa0LDMLIeXnnKN1d
ZaZN7SZS9CmLVGMWxzIeLAfsfDMuSh7cIOl0wWckv9rgecw+KlcXaBvGTUir0svQ5BkKWvDhoBR0
Dw+NMVKpMntGL+5ORykO2kwE7BZJq9IiKnAbTxgy9k6xvFWQvLxnXPleW92tVf7UmWY2cFfNNCgC
i0fYYo9R0GZ2p35eDKLCjgRxPwzLHvdsxhWmuGIns7WEsiRMGcq1qBaxTb42X/esNT5M8g2kVZm1
oX6zTZNdaDKu3NQWsaayBlCifgK+pc+NlC3yhgF8B3h/XMbYM1IljJwzT13O409u4fEnX2LenL5X
uaKzQFfHHTPqJwIBtI/iO7NKYAeCkps6LIwnuBxjoxmD9Zij2M0jrynIPbg+UamMMXdmD3Nm9XLT
HeuYNbOHOTN7CKMaVb5Po4KwkF8NBhoGos14GdwzEYRp8GlhcJiYnojnNZAGheZnzHxU2kyn5LCX
EW/6FoBb6rUMEdV8VEyrLRUEjtSX30chV38aC5fuw6ImNShrCzatsPE+V/xAec6Euk73ZBFvC6tt
Z6Te7prWWt/THYrvMMhbtlohOSmZaxndhNgCzA0jUa2GnH/usZRLASccuYR//s6DPLr2RaZP6/5m
ucScUpk9UehEoRMEQdrJZcDzxLr9vjTt2gp70tAhgi2YzgOuS43IcjnWJaOIH0je+fzGHfze2StZ
snAWxx6xmHXrt3LDT9cyfVp8a09gXGoZzIhFWWnS/L2ZNg27LPFHf239b95wbRgvIQUbid3Q0wtN
bsXqrkdOUM73GpjRlWwMPoASZmneG7VkvER8UhI2NftDM/Ax4D7QIYgT22yPPJt5tR1lBE293mOR
5scCz96Jcu7JDsDDaLfc6ycyG7w+Bd9bny3MjGObfPqSxVclWYbuySaG/oH4YsHiusWhkj5jNCuf
CjBelngYuDF9mhj4Gc5qILy+KPGZkZEqy/edxwFL53DW+67kjFMOoloN2bptNzP6urqikNsNW1kd
jpCEdZfTeLL9iS9BGzBjf2B1wuxhUle3oGpmdwPbhKEwwsOIUk8Hjn0Kcerw0CjVyhjPPLed3imd
7L9kFrNnTuFbP3iIab2dAGvc7KF6Xy0gCbeobQHVvw8bPFmnLQcqdgE3B3MMNkmhGlmyeME6ZYwh
uw90WjFzKolIaNWF04qlmu/tHtB5TQNbNGnuK9x1E6cAL1G707e1uBnrMXtRdVfdk2SDQuv19xJH
VT8IOjWnNZgFeOTrPfLTzCzPkTFKfLw5JfV+5Me/9ReUb4b3NvSnMfu1wjaOoxssAv6q0A2NfYMM
s9T0AXfHq7Hk88jx0PFIX1TkYVdHwGPrXuSuB57h9ccs45Y713HY8n7e9oZDeHHzTuR+xOhw+JM4
+FFEY7EnS+5S5G9R5Cuj0EOPvFfueOTbPXQU+VJFvsU9epuHujcai4MyDaNSiS6qjoUfHR6uMG92
L+e/5ziuu3kNH/yr73L1jx7lmhtXM6OvKz4D436hRyEepecSNA+Y3xy4mHz/PmaHY7YCsxXAvdl8
me+D2a2erOXbmF8dye97c+pqyR8zRRKwR+06qMbfUmMga82DpvpN85bi8dsk7Wk+v5H53dfyLMUX
nzm6Oq0/UZ2uLwwwRDMlnZrzvHYYy8PwfrkfXrfKG9INiIOBgxAHI92ZkweI92ha9TRNPImROo7m
9+T8pvG59Bzoxeyb+qk0B6rCqmBVJYlhIj5iiM5ywJVX3c/apzbzmb84nalTOjh25WJOOGopL7zw
MpKfYoFWYxwUhY5X3TzyAXd/zN03y/1Mj3xJfIWR75D7Fe7+grsvjELf6h6ej4fxrAz4ehDw2eGh
UR5d8wLvOOMwPnje8ey3z2yOOnwhd97/NPf//NmEWfQQVW6galBNvETGAkF/LhnNttRIEhugm/LJ
bf2iRPZedRWQPcH3s/bD1+jmjCVU8zjVBmoVWZXVG183IYqI4+0a0eShbq4/fvF/kgsP0gn/zzTe
VZxfLue5pMvC0bGXkY6sTdTG9GImM6QqZGtaUBMK6WfRycjC1K6ccn4rtnGan7cwi1nyPyOh6hGh
K0lOFOnzHurZAOjuKNE/u49FAzO4+Ms309lRYtni2bGdAkTV8GDwNaAPyX263D9BpIMV+Wm4bsZ1
B65/Q3oI1/Ny/5Tcv4Dr+/LoScnfLHha7uft2DHE/DlTOe+co/jYZ6/jtrvW8flPvI23nnowlZEx
eqeUGauEKNI5ihT7xb02qwaQemvuv1oCpG2KY5eSiclLBfn6jaivTrj8c/WeGu3S/QV4ap+FY9mU
iMONHmhBk743i71qdW/N30s8mIerfbL3Q7AFC7KVjEn8zt7iwoLIQ7+gOjo21YJgen5GtsQMkEoJ
bc6btEj9qp1wpZhQbRskaru3LeXzf6v5eZN0yLhlYs9DyAghww0p0tibo9DpKpd4av0WvnPtw1x2
8TlsenEX1/14FRf+4Uk8t2E7ldFqckuLf17uj3vkG6IoWu6hP+Jh9F4Po3+PouhdXvXXeRj9jUK/
3CP/kIfRVQr1YcR1Y5XqkuHhCuUg4Jwzj+DAZXNZvt883v+hb/Gtqx/k2htX4ZEICHAP/zpk5Mmq
jVBllGoatCyOKRSByh5nBeIjxjmiUl2SH5jozfsUyNQZqLY6b6blhvpaHZ2gcnIXw7SiAZY0O3OP
wJrCSeCaq6hRJTM4DunHE5tMjsGFVgq+RmCxdy6zmWHwXdAf7cXkfCEoBUdWhobGomr1lNpJhJak
kew9CVJ8ECzn1H8ZsTw+86998m8EGC9pSoK1p7V80e+G54sRDfcxNHg00vBf4bXVBoFbtMakP7Cw
dNmcmT3c8JM13PfAMzy2bjPnnnMUHaWAC953Iuuf3cbX/+1e5szuZVpf9/xpfVMuD+MNz5dcrAG9
IGOPiQ6DBcT/xXg/xasBFgSUzXhp6x7mz+1jsH86V/77vXzlkt/iin+9h4s+eQ0HL1/A9KndRF69
UeXosw2Tsq7WPARcQaOiE+9OWPZfSgDGtUloTkteZGmg5RXAoyShyhlBs4eGy6n158CxObhCjN2x
SzxaZUHpisxYZfHdnRmJfxGampdP7rfUJGIpwIISxMGpb5T0PjOOySmX1jECdiVmD9AGDL6i2Hlw
HvERhDyXaQBsNAu+4GG0fWzPCGbB84jm/qX0vClhk+SVXUfsom/NCzuSTc6E9hO+8iV1ij2foHwW
uDwdoAniCICtaor7M0mcfu7/RaGIKhFjDMft8tbtWY0Ff28e/BkmIneeWr+VL/7t2bzx5IP48td+
yo9vfZz3vfs4yuUS3/jmvWzavJPAAqb2drFnqEJ3d5muzvgyF/eYISuVKsMjVTrKAS9t28PH/+LN
HHzAAt509ld49+8czVlvfRVPP7OVH920muc37qB3SieCNXREh8T/Ea7uwrMAbr/6wxOkxyRMwt5B
7v+UNINwFLyS3T8zCPShoBRNwfmAGQzMn8ZNtz7G9Tet5p//9R6u/Kfz2Lp1Dzt2DvP7v3ssn77k
Rxx55GI2b9nN0n1msWeowtatexitVOmb2s2eoQpLFs3iuKOXEQTGV6+4gyfWbeYDv3c8t/7wTzn3
D77Bw49sIAydyljIzBk9eOSPR5GO8tHUu6ckkhc6pr3S5JyE/84w/j0jVk/x1ov+0CP/pIdOX28n
99z7FPc9sJ4l+8yO3c8uli2dw2NrN/Hao/flhGP245lntvInHziJs888gsULZ3La65ezYG4fbz39
MLo6Spx0/AGcdPwBLJjTx8jIGDfctJqvfeMOXr1iETteHiYw6OvppFoJb5W0wsyGW/YfJrrATsIk
/JLQnlms6JF9HNdvj42G1elTu5nZ182s6VP46tduZ0ZfN5WRKl/6ys2cdvJyNm7awZrVG1m9eiPf
/u6DVCshBx3Yz0sv7eLQ5f3cettahofHqFYjjn71Eh566Dm+/NVbufa6R1jz2EbmzOyN/0lu5F9E
nAxWrTWkOU3CJPwnQvlXKPsfiLslfRXpzd1dHYyMVPnEp69FwGD/DO6880lWrdnIEYctQhKjI2Os
XbuNM9+8go986HQeXfU8Y6NV/uny23lh0w66OsuMDFd4ascw+y2ZSxhGVCvVpzH7YyvZj15pYk3C
/2zINfDNnHDI8LHGmD8zsEDxbTDJ9azJpd5vxfko6DVppGa5FLB5yy56erqY1tdFEARUKlV276nQ
v2A6hxw0wB13raOjo8SOHcN0dpbp6CjR1VVON8e2YFxsgf2dBUH8v9EtICjHgZpRqHpQaOI+7ZgG
t39v0sCfhP8c+FVWlixcK3Et8jcC/wvsrdXIu2ZN7wFgbDSkUgnp7CwxfWo3L724i2ee3sKMGT0E
wIxpyd0AAg+je4ArIfi6lWxsUr2ahP8q8OtilhRuRLoRaZbEKYgTie+/2rezFMwhUimMQro7SnRP
7wGxIxqLNgCPYNwF3GzGE21OaEzCJLxi8OtmlhS2A98G/RRYkUQfDxKH6ncR35G1C9hsxtPAKrB1
k6vIJPxXBpvwtZ2TMAn/w+H/AYj52ExYp0NiAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTEyLTMx
VDE4OjEyOjQ0KzAwOjAwhefinwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0xMi0zMVQxODoxMjo0
NCswMDowMPS6WiMAAAA3dEVYdGljYzpjb3B5cmlnaHQAQ29weXJpZ2h0IDE5OTkgQWRvYmUgU3lz
dGVtcyBJbmNvcnBvcmF0ZWQxbP9tAAAAIHRFWHRpY2M6ZGVzY3JpcHRpb24AQWRvYmUgUkdCICgx
OTk4KbC66vYAAAAASUVORK5CYII=" />
</svg>
);

export default Logo;
