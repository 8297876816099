import React, {Fragment} from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/Person';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { useRefresh, translate, Filter, TextInput, SelectInput, Datagrid, List, TextField} from 'react-admin';
import {GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER} from '../../util/constant';
import Pusher from 'pusher-js';
import {BACKEND_URL, PUSHER_API_KEY, PUSHER_CLUSTER,
    PUSHER_CHANNEL_USER, EVENT_ONLINE_DRIVERS_CHANGED,
} from '../../util/variant'

import {DriverStatusField, VerifyField, AvatarEmailField, FullNameField, GenderField, 
    GenderFilterField, LockField, ValidateField, AvatarField} from '../../control/field/index'
// import CardIcon from '../../control/card-icon';
import {NoticeSendButton} from '../../control/button/index'


const styles = theme => ({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop:'1em'
        // marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    listItemText: {
        paddingRight: 0,
        fontSize:'0.5rem',
    },
});

var driverTypes = [];
driverTypes.push({id: USER_TYPE_AUTO_ENTERPRENER, name: "Auto-entrepreneur"});
driverTypes.push({id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre"});

const UserTypeField = ({ record = {}, label}) => {
    return <div>
        <span>{record.user_type == USER_TYPE_COMPANY_AND_DRIVER? 'Autre' : 'Auto-entrepreneur'}</span>
    </div>;
}

const UserTypeFilterField = ({ record }) => {
    return <span>{record.id == USER_TYPE_AUTO_ENTERPRENER ? 'Auto-entrepreneur' : 'Autre'}</span>;
};

var genders = [];
genders.push({id: GENDER_MALE, name: 'Monsieur'});
genders.push({id: GENDER_FEMALE, name: "Madame"});

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        <NoticeSendButton {...props} toClient={false}/>
    </Fragment>
);

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable/>
        <TextInput label="Mobile" source="mobile" alwaysOn resettable/>
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            /> */}
        {/* <SelectInput label="Civilité" source="gender" alwaysOn choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}
        <TextInput label="Nom" source="last_name" resettable/>
        <TextInput label="Prénom" source="first_name" resettable/>
        {/* <TextInput label="Ville" source="city" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
    </Filter>
);


const OnlineDriversDashboard = ({ drivers = [], nb, translate, classes }) => {
    const refresh = useRefresh();
    console.log('Init pushers');
        const onlineDriverPusher = new Pusher(PUSHER_API_KEY, {
            cluster: PUSHER_CLUSTER,
            encrypted: true
          });
          const channel = onlineDriverPusher.subscribe(PUSHER_CHANNEL_USER);
          channel.bind(EVENT_ONLINE_DRIVERS_CHANGED, data => {
            // this.setState({ chats: [...this.state.chats, data], test: '' });
            console.log('Online drivers changed');
            refresh();
          });

    return(
    <List filters = {<ListFilter/>} bulkActionButtons={<BulkActionButtons />}
        resource="driver_online" basePath="/driver_online" exporter={false}>
                <Datagrid isRowSelectable={record => false} hasBulkActions={false}>
                    <DriverStatusField source="online_status" label="Statut"/>
                    <AvatarField source="photo_url" label="" />
                    {/* <GenderField label={translate('civility')}/> */}
                    <TextField source="last_name" label="Nom"/>
                    <TextField source="first_name" label="Prénom"/>
                    {/* <UserTypeField label="Type d'entreprise"/> */}
                    {/* <TextField source="postal_code" label="Code postal"/> */}
                    {/* <TextField source="city" label="Ville"/> */}
                    <TextField source="mobile" label="Mobile"/>
                    <TextField source="email" label="Email"/>
                </Datagrid>
            </List>
);
};

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(OnlineDriversDashboard);
