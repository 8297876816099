
import React, {Link} from 'react';
import { Labeled, SimpleShowLayout,TextField, Show, useTranslate } from 'react-admin';
import { ListButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// import * as moment from 'moment';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {ValidateField, DistanceField, DurationField, FormattedDateField, OrderTypeField} from '../../control/field/index'
import {AssignButton, DeleteButton} from '../../control/button/index'
import { weekDays } from '../../util/constant';

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
    <ListButton/>
        <DeleteButton content="Voulez-vous supprimer cette demande ?" redirect='list' basePath={basePath} record={data} resource={resource}/>
        

        {/* <AssignButton/> */}
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_requested.show')}:#{record ? `${record.order_number}` : ''}</span>;
    
};

const CommentField = ({ source, record = {}, label}) => {
    if(typeof(record) === 'undefined' || record === 'undefined' || record.extra === 'undefined'
    || typeof(record.extra)=== 'undefined') return <span></span>;
    
    // const everyDay = record.every_day[0];
    // if(everyDay === '1')
    // return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const extra = JSON.parse(record.extra); 
    console.log("Extra " + JSON.stringify(extra));
    
    return <span class="MuiTypography-root MuiTypography-body2">{extra.note}</span>;
    
}

CommentField.defaultProps = {
    addLabel: true,
};

const DaysTextField = ({ source, record = {}, label}) => {
    // if(record === 'undefined' || record.every_day === 'undefined'
    // || typeof(record.every_day)=== 'undefined') return <span></span>;
    
    // const everyDay = record.every_day[0];
    // if(everyDay === '1')
    // return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const days = record.repeat_days;
    console.log("Repeat days: "  + days);
    if(days === 'undefined' || typeof(days) === 'undefined')
        return  <span></span>;
    const dayList = days.split(',');
    const length = dayList.length;
    // return <span class="MuiTypography-root MuiTypography-body2">{length}</span>;
    if(length === 1)
        return <span class="MuiTypography-root MuiTypography-body2">{weekDays[dayList[0] - 1]}</span>;

    return dayList.map((day, index) => {
        if(index < length - 1)
            return <span class="MuiTypography-root MuiTypography-body2">{weekDays[day - 1]}, </span>;
        else
            return <span class="MuiTypography-root MuiTypography-body2">{weekDays[day - 1]}</span>;
    })    
}

DaysTextField.defaultProps = {
    addLabel: true,
};

const RepeatFromDateTextField = ({ source, record = {}, label}) => {
    // if(record === 'undefined' || record.every_day === 'undefined'
    // || typeof(record.every_day)=== 'undefined') return <span></span>;
    
    // const everyDay = record.every_day[0];
    // if(everyDay === '1')
    // return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const fromDate = record.repeat_from_date;
    
    // console.log("Repeat days: "  + days);
    if(fromDate === 'undefined' || typeof(fromDate) === 'undefined'
    )
        return  <span></span>;
    return <Labeled label="Début">
        <span class="MuiTypography-root MuiTypography-body2">
    <FormattedDateField record={record} source="repeat_from_date" format="format_date"/></span>
    </Labeled>;

    
}

const RepeatToDateTextField = ({ source, record = {}, label}) => {
    // if(record === 'undefined' || record.every_day === 'undefined'
    // || typeof(record.every_day)=== 'undefined') return <span></span>;
    
    // const everyDay = record.every_day[0];
    // if(everyDay === '1')
    // return <span class="MuiTypography-root MuiTypography-body2">Tous les jours</span>;
    const toDate = record.repeat_to_date;
    // console.log("Repeat days: "  + days);
    if(
    toDate === 'undefined' || typeof(toDate) === 'undefined')
        return  <span></span>;
    return <Labeled label="Fin">
        <span class="MuiTypography-root MuiTypography-body2">
    <FormattedDateField record={record} source="repeat_to_date" format="format_date"/></span>
    </Labeled>;

    
}


// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderUnassigned = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
            {/* <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/> */}
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            
            <div/>
            {/* <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/> */}
            {/* <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/> */}
            <div/>
            <TextField source="c_last_name" label="Nom du passager" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du passager" className={classes.inlineBlock}/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <DistanceField label="Distance" className={classes.inlineBlock}/>
                <DurationField label="Durée" className={classes.inlineBlock}/>
                <div/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge"/>
            <TextField source="passenger_count" label="Nombre de passagers"/>
            {/* <ValidateField source="repeated" label='Récurrent'/> */}
            {/* <RepeatFromDateTextField className={classes.inlineBlock}/>
            <RepeatToDateTextField className={classes.inlineBlock}/> */}
            <div/>
            {/* <DaysTextField label="Jours de récurrence"/> */}
            <CommentField label="Commentaire au conducteur"/>
            <div/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/> */}
            {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
            
            <TextField source="total" label="Prix(€)"/>
            {/* <TextField source="commission_amount" label="Commission(€)"/> */}
            {/* <TextField source="driver_amount" label="Commission chauffeur(€)"/> */}
            {/* <TextField source="pt_name" label="Mode de paiement"/> */}
            {/* <TextField source="driver.email" label="Chauffeur"/> */}
            
            {/* <div/>
            <Divider/>
            <br/>
            <Typography variant="body2">Déroulement</Typography>
            <div/>
            <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/>
            <div/>
            <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/>
            <FormattedDateField source="onboard_time" format="format_date_time" label="Passager à bord" className={classes.inlineBlock}/>
            <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/>
             */}
             {/* <br/> */}
             {/* <AssignButton/> */}
        </SimpleShowLayout>
    </Show>
)};


export default ShowOrderUnassigned;