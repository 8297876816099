
import React, {Link} from 'react';
import { SimpleShowLayout,TextField, Show, useTranslate } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };
import {OrderTypeField, OrderStatusField, FormattedDateField} from '../../control/field/index'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_finished.show')}:#{record ? `${record.order_number}` : ''}</span>;
    
};

const TypeTextField = ({ source, record = {}, label}) => {
    const locationType = locationDict[record.type];
    return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
}
    
TypeTextField.defaultProps = {
    addLabel: true,
};

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderClient = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
            <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
             <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>

            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <div/>
            <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandé"/>
            <div/>
            <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/>
            {/* <TextField source="original_total" label="Montant sans frais d'attente(€)"/> */}
                <TextField source="wait_amount" label="Frais d'attente(€)"/>
                
                <TextField source="total" label="Total(€)"/>
                <TextField source="commission_amount" label="Commission Veebya(€)"/>
                <TextField source="driver_amount" label="Commission chauffeur(€)"/>
                
                {/* <TextField source="tips_amount" label="Pourboire au chauffeur(€)"/> */}
            {/* <TextField source="pt_name" label="Mode de paiement"/> */}
            
            <div/>
            <Divider/>
            <br/>
            <Typography variant="body2">Déroulement</Typography>
            <div/>
            <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/>
            <div/>
            <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/>
            <FormattedDateField source="onboard_time" format="format_date_time" label="Passager à bord" className={classes.inlineBlock}/>
            <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/>
            <div/>
            <FormattedDateField source="end_time" format="format_date_time" label="Heure de fin"/>
            <FormattedDateField source="cancel_time" format="format_date_time" label="Heure d'annulation"/>
        </SimpleShowLayout>
    </Show>
)};


export default ShowOrderClient;